//
// jQuery Repeater
//



.kt-repeater{
	.kt-repeater__row{
		display:flex;
		flex-wrap:wrap;

		> input {
			flex: 1;
		}

		.kt-repeater__close{
			background-color: transparent;
			border: none;
		}

		.form-text {
			flex-basis: 100%;
		}
	}

	.kt-repeater__item{
		position:relative;

		.btn-icon{
			height: auto;
		}

		&:first-child{

			.form-control{
				border-top-right-radius: 0.25rem;
				border-bottom-right-radius: 0.25rem;
			}

			.input-group-append{
				display:none;
			}
		}
	}

	.kt-repeater__close {
		&--align-right{
			position: absolute;
			top: 0;
			right:0;
		}		
	}
}