/* 
*   Pink Theme 
*   Author: Jessiree de Vera | ProSource Dev Team 2019
*/

// Universal Brand Colors

$brand-color: #ea178b;
$brand-color-inverse: #ffffff;

// States

$brand-primary-color: #5867dd;
$brand-primary-inverse-color: #ffffff;

$brand-success-color: #0abb87;
$brand-success-inverse-color: #ffffff;

$brand-info-color: #5578eb;
$brand-info-inverse-color: #ffffff;

$brand-warning-color: #ffb822;
$brand-warning-inverse-color: #111111;

$brand-danger-color: #fd397a;
$brand-danger-inverse-color: #ffffff;

// Base Colors

$brand-base-color-label-1: #a2a5b9;
$brand-base-color-label-2: #74788d;
$brand-base-color-label-3: #595d6e;
$brand-base-color-label-4: #48465b;

$brand-base-color-shape-1: #f0f3ff;
$brand-base-color-shape-2: #e8ecfa;
$brand-base-color-shape-3: #93a2dd;
$brand-base-color-shape-4: #646c9a;

$brand-base-color-grey-1: #f7f8fa;
$brand-base-color-grey-2: #ebedf2;
$brand-base-color-grey-3: darken(#ebedf2, 3%);
$brand-base-color-grey-4: darken(#ebedf2, 6%);

// Fonts 
// See `index.html` for fonts imported. 

$brand-font-regular: unquote('Poppins, Helvetica, sans-serif');
$brand-font-heading: unquote('Poppins, Helvetica, sans-serif');

$brand-font-size-desktop: 13px;
$brand-font-size-tablet: 13px;
$brand-font-size-mobile: 13px;

$brand-font-weight: 300;

$brand-font-color: #2a2f36;
$brand-font-link-default-color: $brand-color;
$brand-font-link-default-hover-color: darken($brand-color, 6%);

// Layout

// 1. Brand (where the logo is located)

$brand-self-bg-color: #272631;
$brand-self-bg-shadow: 0px 1px 9px -3px rgba(0,0,0,0.2);
$brand-self-toggler-color: lighten($brand-color, 20%);

// 2. Progress Bar

$brand-progress-bar-color: #f05cad;

// 3. Aside

$brand-aside-icons-color: lighten($brand-color, 15%) ; // default: #434d6b
$brand-aside-bg-color: #000000;
$brand-aside-text-color-normal: #ffffff;