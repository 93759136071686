//
// Global Config
//

// Init global functions and mixins
@import "global/init";

// + JD: Only added tweaking for brand assets. Metronic dark and light themes are remained untouched. 
@import "global/_theme"; 

// Layout Breakpoints(bootstrap responsive breakpoints)
// Define the minimum and maximum dimensions at which your layout will change, adapting to different screen sizes, for use in media queries.
$kt-media-breakpoints: (
    // Extra small screen / phone
    xs: 0,

    // Small screen / phone
    sm: 576px,

    // Medium screen / tablet
    md: 768px,

    // Large screen / desktop
    lg: 1024px, //1024px,

    // Extra large screen / wide desktop
    xl: 1399px
) !default;

// Global rounded border mode
$kt-rounded: true !default;

// Border Radius
$kt-border-radius: 4px !default;

// Core icon codes(lineawesome icons: https://icons8.com/line-awesome)
$kt-action-icons: (
    close: '\f191',
    down: '\f110',
    up: '\f113',
    left: '\f111',
    right: '\f112',
    plus: '\f2c2',
    minus: '\f28e'
) !default;

// Core bold icon codes(lineawesome icons: https://keenthemes.com/metronic/preview/default/components/icons/flaticon.html)
$kt-action-bold-icons: (
    down: '\f1a3',
    up: '\f1a5',
    left: '\f1a4',
    right: '\f19d',
    close: '\f1b2'
) !default;

// Elevate shadow
$kt-elevate-shadow: 0px 0px 13px 0px rgba(82,63,105,0.05) !default;
$kt-elevate-shadow-2: 0px 0px 13px 0px rgba(82,63,105,0.1) !default;

// Dropdown shadow
$kt-dropdown-shadow: 0px 0px 50px 0px rgba(82,63,105, 0.15) !default;

// Custom scrollbar color
$kt-scroll-color: darken(#ebedf2, 6%) !default;

// Transition
$kt-transition: all 0.3s !default;

// Modal Z-index
$kt-modal-zindex: 1050 !default;

// dropdown Z-index
$kt-dropdown-zindex: 95 !default;

// State Color
$kt-state-colors: (
    // Metronic states
    brand: (
        base: $brand-color,
        inverse: $brand-color-inverse
    ),
    light: (
        base: #ffffff,
        inverse: #282a3c
    ),
    dark: (
        base: #282a3c,
        inverse: #ffffff
    ),

    // Bootstrap states
    primary: (
        base: $brand-primary-color,
        inverse: $brand-primary-inverse-color
    ),
    success: (
        base: $brand-success-color, //1dc9b7,
        inverse: $brand-success-inverse-color
    ),
    info: (
        base: $brand-info-color,
        inverse: $brand-info-inverse-color
    ),
    warning: (
        base: $brand-warning-color,
        inverse: $brand-warning-inverse-color
    ),
    danger: (
        base: $brand-danger-color,
        inverse: $brand-danger-inverse-color
    )
) !default;


// Base colors
$kt-base-colors: (
    label: (
        1: $brand-base-color-label-1,
        2: $brand-base-color-label-2,
        3: $brand-base-color-label-3,
        4: $brand-base-color-label-4
    ),
    shape: (
        1: $brand-base-color-shape-1,
        2: $brand-base-color-shape-2,
        3: $brand-base-color-shape-3,
        4: $brand-base-color-shape-4
    ),
    grey: (
        1: $brand-base-color-grey-1,
        2: $brand-base-color-grey-2,
        3: $brand-base-color-grey-3,
        4: $brand-base-color-grey-4
    )
) !default;

// Social network colors(see: https://brandcolors.net/)
$kt-social-colors: (
    facebook: (
        base: #3b5998,
        inverse: #ffffff
    ),
    google: (
        base: #dc4e41,
        inverse: #ffffff
    ),
    twitter: (
        base: #4AB3F4,
        inverse: #ffffff
    ),
    instagram: (
        base: #517fa4,
        inverse: #ffffff
    ),
    youtube: (
        base: #b31217,
        inverse: #ffffff
    ),
    linkedin: (
        base: #0077b5,
        inverse: #ffffff
    ),
    skype: (
        base: #00aff0,
        inverse: #ffffff
    )
) !default;

// Root Font Settings
$kt-font-families: (
    regular: $brand-font-regular,
    heading: $brand-font-heading
) !default;

// Root Font Settings
$kt-font-size: (
    size: (
        desktop: $brand-font-size-desktop,
        tablet: $brand-font-size-tablet,
        mobile: $brand-font-size-mobile
    ),
    weight: $brand-font-weight
) !default;

// General Link Settings
$kt-font-color: (
    text: $brand-font-color,
    link: (
        default: $brand-font-link-default-color,
        hover: $brand-font-link-default-hover-color
    )
) !default;

// Portlet settings
$kt-portlet: (
    min-height: (
        default: (
            desktop: 60px,
            mobile: 50px
        ),
        sm: (
            desktop: 50px,
            mobile: 40px
        ),
        lg: (
            desktop: 80px,
            mobile: 60px
        ),
        xl: (
            desktop: 100px,
            mobile: 80px
        )
    ),
    space: (
        desktop: 25px,
        mobile: 15px
    ),
    bottom-space: (
        desktop: 20px,
        mobile: 20px
    ),
    border-color: kt-base-color(grey, 2),
    bg-color: #ffffff,
    shadow: 0px 0px 30px 0px rgba(82,63,105,0.05)
) !default;

// Page padding
$kt-page-padding: (
	desktop: 25px,
	mobile: 15px
) !default;

// Page container width
$kt-page-container-width: 1380px !default;

// Custom Scroll(Perfect Scrollbar) size
$kt-custom-scroll-size: 4px !default;
