/* auto-grid.css by Jessiree de Vera | 2019 (c) ProSource Dev Team */
.auto-grid, .auto-grid-auto-grid-x1{
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem
}

.auto-grid.auto-grid-x2 {
    grid-template-columns: 1fr 1fr;
}

.auto-grid.auto-grid-x3 {
    grid-template-columns: 1fr 1fr 1fr;
}

.auto-grid.auto-grid-x4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.auto-grid.auto-grid-3x9 { /* mimics bootstap col-sm-3 col-sm-9*/
    grid-template-columns: .75fr 1.25fr;
}

.auto-grid.auto-grid-9x3 { /* mimics bootstap col-sm-9 col-sm-3*/
    grid-template-columns:  1.25fr .75fr;
}

.auto-grid.auto-grid-10x2 { /* mimics bootstap col-sm-10 col-sm-2*/
    grid-template-columns:  1.75fr .25fr;
}

@media(max-width: 1024px){
    .auto-grid.auto-grid-x3, .auto-grid.auto-grid-x4, .auto-grid.auto-grid-3x9, .auto-grid.auto-grid-9x3, .auto-grid.auto-grid-10x2{
        grid-template-columns: 1fr 1fr;
    }
}

@media(max-width: 500px){
    .auto-grid.auto-grid-x3, .auto-grid.auto-grid-x4, .auto-grid.auto-grid-3x9, .auto-grid.auto-grid-9x3, .auto-grid.auto-grid-10x2{
        grid-template-columns: 1fr;
    }
}